<template>
    <div>
        <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread>
        <div class="nav">
            <span style="margin-left:63px;" @click="navv(1)" :class="na==1?'na1':''">
                <b>|</b> 气象类</span>
            <span @click="navv(2)" :class="na==2?'na1':''">
                <b>|</b> 环保类</span>
            <!-- <span @click="navv(3)" :class="na==3?'na1':''">
                <b>|</b> 极端天气</span> -->
        </div>
        <button style=" margin-left:98px;" :class="btna" @click="sub()" v-if="na==1">气象</button>
        <button :class="btna5" @click="sub5()" v-if="na==1">天气预警信息</button>

        <!-- <button style=" margin-left:98px;" :class="btna2" @click="sub2()" v-if="na==2">大气质量日报</button> -->
        <button :class="btna3" @click="sub3()" v-if="na==2">大气质量时报</button>
        <!-- <button :class="btna4" @click="sub4()" v-if="na==2">酸雨</button> -->

        <!-- <button style=" margin-left:98px;" :class="btna6" @click="sub6()" v-if="na==3">地震数据</button>
        <button :class="btna7" @click="sub7()" v-if="na==3">台风信息</button>
        <button :class="btna8" @click="sub8()" v-if="na==3">台风路径信息</button>
        <button :class="btna9" @click="sub9()" v-if="na==3">台风预估点</button> -->
        <router-view class="vie"></router-view>
    </div>
</template>
<script>
export default {
    name: "servicedata_management",
    data() {
        return {
            itemName: '共享服务数据',
            breadlist: [
                {
                    path: '',
                    name: '数据管理与接入'
                }, {
                    path: '',
                    name: '数据管理'
                }
            ],
            btna: 'btna2',
            btna2: 'btna2',
            btna3: 'btna2',
            btna4: 'btna2',
            btna5: 'btna2',
            btna6: 'btna2',
            btna7: 'btna2',
            btna8: 'btna2',
            btna9: 'btna2',
            reveal: true,
            reveal2: false,
            na: 1
        }
    },
    methods: {
        sub() {
            this.btna = 'btna'
            this.btna5 = 'btna2'
            this.$router.push({ name: "servicedata_qx_grid", query: { state: "collectdataEditable" } })
        },
        sub5() {
            this.btna5 = 'btna'
            this.btna = 'btna2'
            this.$router.push({ name: "servicedata_yjxx_grid", query: { state: "collectdataEditable" } })
        },
        sub2() {
            this.btna2 = 'btna'
            this.btna3 = 'btna2'
            this.btna4 = 'btna2'
            this.$router.push({ name: "servicedata_dqzl_grid", query: { state: "collectdataEditable" } })
        },
        sub3() {
            this.btna3 = 'btna'
            this.btna2 = 'btna2'
            this.btna4 = 'btna2'
            this.$router.push({ name: "servicedata_dqzlsb_grid", query: { state: "collectdataEditable" } })
        },
        sub4() {
            this.btna4 = 'btna'
            this.btna2 = 'btna2'
            this.btna3 = 'btna2'
            this.$router.push({ name: "servicedata_syysj_grid", query: { state: "collectdataEditable" } })
        },
        sub6() {
            this.btna6 = 'btna'
            this.btna7 = 'btna2'
            this.btna8 = 'btna2'
            this.btna9 = 'btna2'
            this.$router.push({ name: "servicedata_dzsj_grid", query: { state: "collectdataEditable" } })
        },
        sub7() {
            this.btna7 = 'btna'
            this.btna6 = 'btna2'
            this.btna8 = 'btna2'
            this.btna9 = 'btna2'
            this.$router.push({ name: "servicedata_tfxx_grid", query: { state: "collectdataEditable" } })
        },
        sub8() {
            this.btna8 = 'btna'
            this.btna6 = 'btna2'
            this.btna7 = 'btna2'
            this.btna9 = 'btna2'
            this.$router.push({ name: "servicedata_tflj_grid", query: { state: "collectdataEditable" } })
        },
        sub9() {
            this.btna9 = 'btna'
            this.btna6 = 'btna2'
            this.btna7 = 'btna2'
            this.btna8 = 'btna2'
            this.$router.push({ name: "servicedata_tfygd_grid", query: { state: "collectdataEditable" } })
        },

        navv(num) {
            this.na = num
            num == 1 ? this.sub() : num == 2 ? this.sub3() : num == 3 ? this.sub6() : ''
        }


    },
    mounted() {
        this.$route.name == "servicedata_qx_grid" || this.$route.name == "servicedata_yjxx_grid" ? this.navv(1) : this.$route.name == "servicedata_dqzl_grid" || this.$route.name == "servicedata_dqzlsb_grid" || this.$route.name == "servicedata_syysj_grid" ? this.navv(2) : this.navv(3)
    }
}
</script>

<style>
.vie {
    width: 100%;
    height: calc(100% - 195px);
    margin-top: 20px;
}

.btna {
    background-color: #fff;
    width: 92px;
    height: 40px;
    border: none;
    cursor: pointer;
    color: #fff;
    background-color: #1862ad;
}

.btna2 {
    background-color: #fff;
    width: 92px;
    height: 40px;
    border: none;
    cursor: pointer;
    color: rgb(119, 119, 120);
}

.vie .src-css-monitorData-navigation-navigation-6G4D {
    opacity: 0;
    width: 0;
    height: 0;
}

.nav span {
    width: 150px;
    height: 70px;
    font-size: 22px;
    line-height: 70px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    color: rgb(119, 119, 120);
}

.nav span b {
    margin: 0 8px;
}

.nav .na1 {
    color: #1862ad;
}
</style>
